import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { MsalProvider } from "@azure/msal-react";
import {
  Configuration,
  ProtocolMode,
  PublicClientApplication,
} from "@azure/msal-browser";
import "@fortawesome/fontawesome-free/css/all.min.css";

const configuration: Configuration = {
  auth: {
    clientId: "53243a25-6e84-4b4e-9be3-40655b7bb9a6",
    authority: "https://login.microsoftonline.com/upslide.net/",
    protocolMode: ProtocolMode.AAD,
  },
  system: {},
};

const pca = new PublicClientApplication(configuration);

ReactDOM.render(
  <React.StrictMode>
    <MsalProvider instance={pca}>
      <Suspense fallback={null}>
        <App />
      </Suspense>
    </MsalProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
