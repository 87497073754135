import {
  TokenCallback,
  scopes,
} from "@internal-tools/azure-toolkit/lib/security";
import { decode, JwtPayload } from "jsonwebtoken";
import { id_project, id_repo } from "./projectConfig";

export async function allowForcePushOnBranch(
  branchName: string,
  tokenCallback: TokenCallback
) {
  const branchCode = codeBranchNameForSecurity(branchName);
  const token = await tokenCallback(scopes);
  const decodedToken = decode(token) as JwtPayload;
  const tid = decodedToken["tid"];
  const upn = decodedToken["upn"];

  const response = await fetch(
    "https://dev.azure.com/upslidepyramid/_apis/AccessControlEntries/2e9eb7ed-3c0a-47d4-87c1-0ffdd275fd87",
    {
      method: "post",
      headers: {
        authorization: `Bearer ${token}`,
        "content-type": "application/json",
      },
      body: JSON.stringify({
        token: `repoV2/${id_project}/${id_repo}/refs/heads/${branchCode}/`,
        merge: true,
        accessControlEntries: [
          {
            descriptor: `Microsoft.IdentityModel.Claims.ClaimsIdentity;${tid}\\${upn}`,
            allow: 128,
            deny: 0,
            extendedInfo: {
              effectiveAllow: 128,
              effectiveDeny: 0,
              inheritedAllow: 128,
              inheritedDeny: 0,
            },
          },
        ],
      }),
    }
  );

  if (!response.ok)
    throw new Error(`Request failed with status ${response.status}`);
  return response;
}

// https://devblogs.microsoft.com/devops/git-repo-tokens-for-the-security-service/
function codeBranchNameForSecurity(branchName: string) {
  return Array.from(branchName)
    .map((char) => {
      if (char === "/") return "/";
      const charCode = char.charCodeAt(0);
      return charCode.toString(16) + "00";
    })
    .join("");
}
