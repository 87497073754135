import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useMsal,
} from "@azure/msal-react";
import { CenteredCard } from "./CenteredCard";
import { Main } from "./Main";

function App() {
  const { instance } = useMsal();

  return (
    <>
      <AuthenticatedTemplate>
        <Main />
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <CenteredCard>
          <div className="text-gray-700 px-4 py-4 text-xl flex flex-row justify-center items-center">
            <div className="cursor-pointer text-md mx-2 uppercase tracking-wider text-blue-900">
              Release Monitoring
            </div>
          </div>
          <button
            onClick={() =>
              instance
                .loginPopup({
                  scopes: [
                    "499b84ac-1321-427f-aa17-267ca6975798/user_impersonation",
                    "ChannelMessage.Send",
                  ],
                })
                .then(console.log)
                .catch(console.error)
            }
            className="p-6 border-b w-full border-gray-300 border-t text-gray-700 flex flex-row justify-between items-center hover:bg-gray-100 hover:shadow-inner transition duration-200"
          >
            <span className="truncate mr-2">
              Se connecter avec un compte Microsoft
            </span>
            <i className="fa fa-chevron-right" />
          </button>
          <div className="px-4 py-4 italic text-xs text-gray-500 text-center">
            Crafted with 💕 by UpSlide's Dev Team
          </div>
        </CenteredCard>
      </UnauthenticatedTemplate>
    </>
  );
}
export default App;
