import cn from "classnames";
import { ReactNode } from "react";

export interface CenteredCardProps {
  children: ReactNode;
  className?: string;
}

export function CenteredCard(props: CenteredCardProps) {
  return (
    <div
      className={cn(
        "sm:mx-auto sm:w-96 my-56 max-w-full w-auto bg-white shadow-xl rounded mx-6",
        props.className
      )}
    >
      {props.children}
    </div>
  );
}
