import { TokenCallback } from "@internal-tools/azure-toolkit/lib/security";

const UpSlideEngineeringGroupId = "a057be27-2921-4999-887a-28bf5074de73";
const DefaultChannel = {
  id: "19:ee0f359d09c94e9c90485d418a0b5eb4@thread.skype",
  name: "devs",
};

export interface Channel {
  id: string;
  name: string;
}

export class Teams {
  channel: Channel;

  constructor(channel?: Channel) {
    this.channel = Teams.overrideWith(DefaultChannel, channel);
  }

  async sendTeamsMessageWithMention(
    subject: string,
    content: string,
    mentionId: number,
    tokenCallback: TokenCallback
  ): Promise<void> {
    const response = await fetch(
      `https://graph.microsoft.com/v1.0/teams/${UpSlideEngineeringGroupId}/channels/${this.channel.id}/messages`,
      {
        method: "post",
        headers: {
          "content-type": "application/json",
          authorization: `Bearer ${await tokenCallback([
            "ChannelMessage.Send",
          ])}`,
        },
        body: JSON.stringify({
          body: {
            content,
            contentType: "html",
            messageType: "announcement",
          },
          importance: "high",
          mentions: [this.channelMention(mentionId)],
          subject,
        }),
      }
    );

    await response.json();
  }

  channelMarkup(mentionId: number): string {
    return `<at id="${mentionId}">${this.channel.name}</at>`;
  }

  private channelMention(id: number): any {
    return {
      id,
      mentionText: this.channel.name,
      mentioned: {
        conversation: {
          id: this.channel.id,
          displayName: this.channel.name,
          conversationIdentityType: "channel",
        },
      },
    };
  }

  private static overrideWith<T>(
    initialValue: T,
    override: T | undefined | null
  ): T {
    console.log(`'${initialValue}', '${override}'`);
    if (!!override && !Object.values(override).every((v) => !v))
      return override;

    return initialValue;
  }
}
