import { useMsal } from "@azure/msal-react";
import { useCallback } from "react";
import { resourceCache, useAsyncResource } from "use-async-resource";
import {
  Branch,
  branchIsObsolete,
  branchIsStale,
  CompactBranch,
  getBranchWithStats,
  isOlder,
  refNameToBranchName,
} from "./Branch";
import { Teams } from "./Teams";
import { getVersion } from "./Version";
import {
  AzureRepository,
  AzureRequestHelper,
} from "@internal-tools/azure-toolkit";
import { TokenCallback } from "@internal-tools/azure-toolkit/lib/security";
import { allowForcePushOnBranch } from "./azure/azureSecurity";
import { client_project, client_repo } from "./azure/projectConfig";

const release = `${process.env.REACT_APP_RELEASE_BRANCH_PREFIX ?? ""}release`;
async function listReleaseBranches(repository: AzureRepository) {
  return await repository.ListRefs(`heads/${release}/`);
}
async function listHotFixBranches(repository: AzureRepository) {
  return await repository.ListRefs(`heads/hotfix/`);
}
export function Main() {
  const tokenCallback = useTokenCallback();
  const repository = getRepository(tokenCallback);
  const [getReleaseBranches, updateReleaseBranches] = useAsyncResource(
    listReleaseBranches,
    repository
  );
  function refreshReleaseBranches() {
    resourceCache(listReleaseBranches).clear();
    updateReleaseBranches(repository);
  }

  const [getHotfixBranches, updateHotfixBranches] = useAsyncResource(
    listHotFixBranches,
    repository
  );
  function refreshHotfixBranches() {
    resourceCache(listHotFixBranches).clear();
    updateHotfixBranches(repository);
  }
  const [getObsoleteBranches, updateObsoleteBranches] = useAsyncResource(
    getObsoleteBranchesAsync,
    repository
  );
  function refreshObsoleteBranches() {
    resourceCache(getObsoleteBranchesAsync).clear();
    updateObsoleteBranches(repository);
  }

  const [getStaleBranches, updateStaleBranches] = useAsyncResource(
    getStaleBranchesAsync,
    repository
  );
  function refreshStaleBranches() {
    resourceCache(getStaleBranchesAsync).clear();
    updateStaleBranches(repository);
  }

  const releaseRefs = getReleaseBranches();
  const hotfixRefs = getHotfixBranches();
  const obsoleteBranches = getObsoleteBranches();
  const staleBranches = getStaleBranches();

  async function createNewHotfixBeta() {
    const branchName = "master";
    const version = await getVersion(repository, branchName);

    const nextHotfixVersion = window.prompt(
      `${branchName} is ${version.toString()}, hotfix version:`,
      `${version.x}.${version.y}.${version.z}.${version.build + 1}`
    );

    if (!nextHotfixVersion) return;

    const newBranchName = `hotfix/${nextHotfixVersion}`;
    const createResult = await repository.createBranch(
      newBranchName,
      branchName
    );

    const success: boolean = createResult.value[0]?.success ?? false;

    if (!success) alert("An error occurred");

    refreshHotfixBranches();
  }

  async function createNewRelease() {
    const develop = await getVersion(repository, "develop");

    const releaseVersion = window.prompt(
      `Develop is ${develop.toString()}, release version:`,
      `${develop.x}.${develop.y}.${develop.z + 1}.1`
    );

    if (!releaseVersion) return;

    if (releaseVersion.endsWith(".0")) {
      alert("Revision 0 is not a valid revision, use 1 instead. Revision 0 will automatically be created by UpSlide Push to Beta pipeline when revision 1 is created.");
      return;
    }

    const newBranchName = `${release}/${releaseVersion}`;
    const createResult = await repository.createBranch(
      newBranchName,
      "develop"
    );

    const success: boolean = createResult.value[0]?.success ?? false;

    if (!success) alert("An error occurred");

    refreshReleaseBranches();
    const teams = new Teams({
      id: process.env.REACT_APP_TEAMS_CHANNEL_ID ?? "",
      name: process.env.REACT_APP_TEAMS_CHANNEL_NAME ?? "",
    });
    const mentionId = 0;
    await teams.sendTeamsMessageWithMention(
      `Release ${releaseVersion}`,
      `<div>${teams.channelMarkup(
        mentionId
      )}, release branch (${newBranchName}) has beeen created!</div>`,
      mentionId,
      tokenCallback
    );
  }

  async function deleteBranch(branchName: string) {
    if (!window.confirm(`Do you confirm deletion of ${branchName}?`)) return;
    await allowForcePushOnBranch(branchName, tokenCallback);
    await repository.deleteBranch(branchName);
    refreshAllBranches();
  }

  function refreshAllBranches() {
    refreshReleaseBranches();
    refreshHotfixBranches();
    refreshObsoleteBranches();
    refreshStaleBranches();
  }

  return (
    <>
      <div className="bg-white shadow-xl rounded-lg p-8 m-2">
        <div className="flex flex-row justify-between items-center">
          <h1 className="uppercase text-gray-600 tracking-wide mb-2">
            Branches
          </h1>
          <div>
            <button
              className="rounded bg-blue-600 p-2 m-2 text-white uppercase text-xs"
              onClick={createNewHotfixBeta}
            >
              New hotfix beta
            </button>
            <button
              className="rounded bg-blue-600 p-2 m-2 text-white uppercase text-xs"
              onClick={createNewRelease}
            >
              New release
            </button>
          </div>
        </div>
        <ul>
          {hotfixRefs.value.map((ref) => {
            const name = refNameToBranchName(ref.name);
            return (
              <Branch
                key={name}
                branchName={name}
                creator={ref.creator}
                onDelete={deleteBranch}
                repository={repository}
              />
            );
          })}
          {releaseRefs.value.map((ref) => {
            const name = refNameToBranchName(ref.name);
            return (
              <Branch
                key={name}
                branchName={name}
                creator={ref.creator}
                onDelete={deleteBranch}
                repository={repository}
              />
            );
          })}
          <Branch key="develop" branchName="develop" repository={repository} />
          <Branch key="master" branchName="master" repository={repository} />
        </ul>
      </div>
      <div className="bg-white shadow-xl rounded-lg p-8 m-2">
        <h1 className="uppercase text-gray-600 tracking-wide mb-2">
          Obsolete branches
        </h1>
        <ul>
          {obsoleteBranches.map((branch) => {
            const name = refNameToBranchName(branch.name);
            return (
              <CompactBranch
                key={name}
                branchName={name}
                creator={branch.creator}
                onDelete={deleteBranch}
                repository={repository}
              />
            );
          })}
        </ul>
      </div>
      <div className="bg-white shadow-xl rounded-lg p-8 m-2">
        <h1 className="uppercase text-gray-600 tracking-wide mb-2">
          Stale branches
        </h1>
        <ul>
          {staleBranches.map((branch) => {
            const name = refNameToBranchName(branch.name);
            return (
              <CompactBranch
                key={name}
                branchName={name}
                creator={branch.creator}
                onDelete={deleteBranch}
                repository={repository}
              />
            );
          })}
        </ul>
      </div>
    </>
  );
}

// // eslint-disable-next-line @typescript-eslint/no-unused-vars
// function getFilePaths(branchName: string, tokenCallback: TokenCallback) {
//   return fetchAzDevopsForGit(
//     "filePaths",
//     { "versionDescriptor.version": branchName },
//     {},
//     tokenCallback
//   );
// }

export function useTokenCallback() {
  const { instance } = useMsal();

  return useCallback(
    async (scopes: string[]) => {
      const activeAccount = instance.getActiveAccount();
      const accounts = instance.getAllAccounts();

      if (!activeAccount && accounts.length < 1)
        throw new Error("User is not logged in");

      const authResult = await instance.acquireTokenSilent({
        scopes,
        account: activeAccount || accounts[0],
      });

      return authResult.accessToken;
    },
    [instance]
  );
}

async function getObsoleteBranchesAsync(repository: AzureRepository) {
  const branches = await repository.ListRefs("heads/");
  const branchesWithStats = await Promise.all(
    branches.value.map(async (b) => await getBranchWithStats(repository, b))
  );

  return branchesWithStats
    .filter((br) => branchIsObsolete(br))
    .sort((a, b) => isOlder(a, b));
}

async function getStaleBranchesAsync(repository: AzureRepository) {
  const branches = await repository.ListRefs("heads/");
  const branchesWithStats = await Promise.all(
    branches.value.map(async (b) => await getBranchWithStats(repository, b))
  );

  return branchesWithStats
    .filter((br) => branchIsStale(br))
    .sort((a, b) => isOlder(b, a));
}

function getRepository(tokenCallback: TokenCallback): AzureRepository {
  return new AzureRepository(
    client_project,
    client_repo,
    new AzureRequestHelper(tokenCallback)
  );
}
