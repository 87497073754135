import { useAsyncResource } from "use-async-resource";
import {
  AzResponse,
  BranchStats,
  PullRequestSummary,
  RefCreator,
  Ref,
  AzureRepository,
} from "@internal-tools/azure-toolkit";

import { isWip, PullRequest } from "./PullRequest";
import { getVersion } from "./Version";
import { getPullRequestUrl } from "./azure/projectConfig";
import { File, GitBranch, Minus, Plus, User } from "react-feather";

const baseBranchName = "master";
const smallIconSize = 16;
const largeIconSize = 24;

export interface BranchProps {
  repository: AzureRepository;
  branchName: string;
  creator?: RefCreator;
  onDelete?: (branchName: string) => unknown;
}
export function Branch({
  branchName,
  creator,
  onDelete,
  repository,
}: BranchProps) {
  const [statReader] = useAsyncResource(
    getBranchStats,
    repository,
    branchName,
    baseBranchName
  );

  const [versionReader] = useAsyncResource(getVersion, repository, branchName);

  const [pullRequestReader] = useAsyncResource(
    getPullRequestsTargetting,
    repository,
    branchName
  );
  const pullRequests = pullRequestReader();
  const branchStats = statReader();
  return (
    <li className="ml-2 rounded border-2 border-gray-100 m-1 p-1">
      <GitBranch
        size={largeIconSize}
        className="inline ml-1 mr-1 text-gray-600"
      />
      <span className="text-lg ml-1 mr-1 font-bold">{branchName}</span>{" "}
      <BranchStatistics baseBranchName={baseBranchName} stats={branchStats} />
      <BranchCreatorName name={creator?.displayName} />
      <BranchVersion version={versionReader().toString()} />
      <DeleteButton onDelete={onDelete} branchName={branchName} />
      {pullRequests.count > 0 && (
        <ul>
          {pullRequests.value
            .filter((pr) => !pr.isDraft && !isWip(pr))
            .map((pr) => {
              return (
                <PullRequest
                  className="ml-4 mt-4 mb-4"
                  key={pr.pullRequestId}
                  title={pr.title}
                  url={getPullRequestUrl(pr)}
                  authorName={pr.createdBy.displayName}
                  authorImage={pr.createdBy.imageUrl}
                  status={pr.status}
                  mergeStatus={pr.mergeStatus}
                  sourceBranch={refNameToBranchName(pr.sourceRefName)}
                />
              );
            })}
        </ul>
      )}
    </li>
  );
}

export function CompactBranch({
  branchName,
  creator,
  onDelete,
  repository,
}: BranchProps) {
  const [statReader] = useAsyncResource(
    getBranchStats,
    repository,
    branchName,
    baseBranchName
  );

  const [versionReader] = useAsyncResource(getVersion, repository, branchName);

  const branchStats = statReader();
  return (
    <li className="ml-2 m-1">
      <DeleteButton onDelete={onDelete} branchName={branchName} />
      <span className="ml-1 mr-1 font-bold">{branchName}</span>{" "}
      <BranchStatistics baseBranchName={baseBranchName} stats={branchStats} />
      <BranchCreatorName name={creator?.displayName} />
      <BranchVersion version={versionReader().toString()} />
    </li>
  );
}

export async function getBranchStats(
  repository: AzureRepository,
  branchName: string,
  base: string
): Promise<BranchStats> {
  return await repository.getBranchStats(branchName, base);
}

export interface BranchWithStats extends Ref {
  stats: BranchStats;
}

export async function getBranchWithStats(
  repository: AzureRepository,
  branch: Ref
): Promise<BranchWithStats> {
  const branchName = refNameToBranchName(branch.name);
  const stats = await getBranchStats(repository, branchName, baseBranchName);
  return { ...branch, stats };
}

export function isOlder(a: BranchWithStats, b: BranchWithStats) {
  if (isBehind(a) === isBehind(b))
    return b.stats.behindCount - a.stats.behindCount;
  return isBehind(a) ? -1 : 1;
}

function isAnException(branch: BranchWithStats) {
  return /^(POC|client|chore)\/.+/i.test(refNameToBranchName(branch.name));
}

export function branchIsObsolete(branch: BranchWithStats) {
  return !isAnException(branch) && isBehind(branch);
}

export function branchIsStale(branch: BranchWithStats) {
  const isVeryOld = branch.stats.behindCount > 200;
  //TODO: Add a check “No Pull Request”
  return !isAnException(branch) && !isBehind(branch) && isVeryOld;
}

function isBehind(branch: BranchWithStats) {
  return !branch.stats.aheadCount && !!branch.stats.behindCount;
}

export async function getPullRequestsTargetting(
  repository: AzureRepository,
  branchName: string
): Promise<AzResponse<PullRequestSummary>> {
  return await repository.getPullRequestsTargetting(branchName);
}

export function refNameToBranchName(ref: string): string {
  return ref.split("/").slice(2).join("/");
}

interface BranchVersionProps {
  version: string;
}
function BranchVersion({ version }: BranchVersionProps) {
  return (
    <>
      <span className="ml-1 mr-1">
        <File size={smallIconSize} className="inline" /> {version}
      </span>{" "}
    </>
  );
}

interface BranchCreatorNameProps {
  name: string | undefined;
}
function BranchCreatorName({ name }: BranchCreatorNameProps) {
  if (!!name)
    return (
      <>
        <span className="ml-1 mr-1">
          <User size={smallIconSize} className="inline" />{" "}
          <span className="text-gray-600 italic">{name}</span>
        </span>{" "}
      </>
    );
  return <></>;
}

interface BranchStatisticsProps {
  baseBranchName: string;
  stats: BranchStats;
}
function BranchStatistics({ baseBranchName, stats }: BranchStatisticsProps) {
  const ahead = stats.aheadCount;
  const behind = stats.behindCount;
  if (ahead || behind)
    return (
      <>
        <span className="ml-1 mr-1">
          {
            <>
              &nbsp;(<em>{baseBranchName}: </em>
              {!!ahead && (
                <>
                  <Plus className="inline" size={smallIconSize} />
                  {ahead}
                </>
              )}
              {!!behind && (
                <>
                  <Minus className="inline" size={smallIconSize} />
                  {behind}
                </>
              )}
              )
            </>
          }
        </span>{" "}
      </>
    );
  return <></>;
}
interface DeleteButtonProps {
  onDelete: ((branchName: string) => unknown) | undefined;
  branchName: string;
}

function DeleteButton({ onDelete, branchName }: DeleteButtonProps) {
  return (
    <span className="ml-1 mr-1">
      {onDelete && (
        <>
          &nbsp;
          <button
            className="rounded bg-red-600 p-2 text-white uppercase text-xs"
            onClick={() => onDelete?.(branchName)}
          >
            Delete
          </button>
        </>
      )}
    </span>
  );
}
