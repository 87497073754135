import { PullRequestSummary } from "@internal-tools/azure-toolkit";

export const id_project = "6694b7e1-3187-4d06-b5da-cd0708c35209";
export const id_repo = "5257db51-721b-413c-999b-1ec2994edebf";
export const organisation = "upslidepyramid";
export const client_project = "UpSlide%20Client";
export const client_repo = "UpSlide";

export function getPullRequestUrl(pr: PullRequestSummary): string {
  return `https://dev.azure.com/${organisation}/${client_project}/_git/${client_repo}/pullrequest/${pr.pullRequestId}`;
}
