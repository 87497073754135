import { AzureRepository } from "@internal-tools/azure-toolkit";

class Version {
  x: number;
  y: number;
  z: number;
  build: number;
  toString(): string {
    if ([this.x, this.y, this.z, this.build].every((v) => v === 0))
      return "No version found.";
    return `${this.x}.${this.y}.${this.z}.${this.build}`;
  }

  constructor(x: number, y: number, z: number, build: number) {
    this.x = x;
    this.y = y;
    this.z = z;
    this.build = build;
  }

  static fromAssemblyFileContent(content: string): Version {
    if (/(?<=AssemblyFileVersion\(")(\d+)\.(\d+)\.(\d+)\.(\d+)/.test(content)) {
      const x = Number(RegExp.$1).valueOf();
      const y = Number(RegExp.$2).valueOf();
      const z = Number(RegExp.$3).valueOf();
      const build = Number(RegExp.$4).valueOf();

      return new Version(x, y, z, build);
    }
    return new Version(0, 0, 0, 0);
  }
}
export async function getVersion(
  repository: AzureRepository,
  branchName: string
): Promise<Version> {
  const assemblyInfoContent = await repository.getFileContent(
    "Common/Properties/AssemblyInfo.cs",
    branchName
  );
  return Version.fromAssemblyFileContent(assemblyInfoContent);
}
