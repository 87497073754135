import {
  PullRequestMergeStatus,
  PullRequestSummary,
} from "@internal-tools/azure-toolkit";
import { AlertCircle, GitBranch, GitPullRequest } from "react-feather";

export interface PullRequestProps {
  title: string;
  url: string;
  authorName: string;
  authorImage: string;
  status: string;
  mergeStatus: PullRequestMergeStatus;
  sourceBranch: string;
  className: string;
}
const largeIconSize = 24;
const smallIconSize = 16;

export function PullRequest(props: PullRequestProps): JSX.Element {
  const isConflict = props.mergeStatus === PullRequestMergeStatus.conflicts;
  return (
    <li className={props.className}>
      <p>
        <GitPullRequest
          size={largeIconSize}
          className="inline ml-1 mr-1 text-gray-500"
        />{" "}
        <img
          alt={props.authorName}
          src={props.authorImage}
          className="inline"
        />{" "}
        {isConflict && (
          <AlertCircle
            className="inline text-red-500 mr-1"
            size={smallIconSize}
          />
        )}
        <a className="text-lg" href={props.url}>
          {props.title}
        </a>
      </p>
      <p className="ml-8">
        <GitBranch
          size={smallIconSize}
          className="inline ml-1 mr-1 text-gray-500"
        />
        {props.sourceBranch}
      </p>
    </li>
  );
}

export function isWip(pr: PullRequestSummary): unknown {
  const labels = pr.labels;
  if (!!labels) return pr.labels.some((l) => l.name === "WIP" && l.active);
  return false;
}
